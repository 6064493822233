import React from "react";
import "./Header.css";
import { useTranslation } from "react-i18next";

const Header = () => {
  const [t, i18n] = useTranslation("global");

  const handleChangeLang = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div className="header-wrapper">
      <div className="flexCenter header-container">
        <div className="flexCenter">
          <img src="../assets/images/logoNoreaCityRP.png" alt="logo" width={85} />
        </div>
        <div className="flexCenter headerTitle">
          {/* <h1>Norea City Role Play</h1> */}
          <img src="../assets/images/Headingletter.png" alt="Title" width={420}/>
        </div>
        <div className="flexCenter headerFlag">
          <h1>LANGUAGE: </h1>
          <img
            onClick={() => handleChangeLang("en")}
            src="../assets/images/en.png"
            alt="enflag"
            width={25}
          />
          <img
            onClick={() => handleChangeLang("kh")}
            src="../assets/images/kh.png"
            alt="khflag"
            width={25}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
