import React, { useEffect, useState } from "react";
import "./Announcement.css";
import { api } from "../../utils/api";
import { toast } from "react-toastify";

const Announcement = () => {
  const [newPlayer, setNewPlayer] = useState<any[]>([]);

  useEffect(() => {
    api
      .get("/player/getform")
      .then((res) => {
        if (res.status === 201) {
          const passPlayer = res.data.result.filter(
            (register) => register.status === 1
          );
          setNewPlayer(passPlayer);
        } else toast.error("Something went wrong!");
      })
      .catch((err) => {
        console.error(err);
      });
  }, [newPlayer]);

  return (
    <div className="flexCenter announWrapper">
      <div className="flexColCenter announContainer">
        <div className="flexCenter announTitle">
          <h1>
            CONGRATULATION!!!
          </h1>
        </div>
        <div className="flexStart announDetail">
          <img
            src="../assets/images/norea3d.png"
            alt="PosterImage"
            width={450}
          />
          <div className="flexColStart announNameList">
            {newPlayer.map((e, i) => (
              <div className="flexColStart" key={i}>
                <h2>
                  {i + 1 + ". "} {e.name1 + " / " + e.name2}
                </h2>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Announcement;
