import axios from "axios";
import { toast } from "react-toastify";

export const api = axios.create({
  baseURL: "http://noreacity.net:8080/api",
  // baseURL: "http://localhost:8080/api",
});

export const getAllForms = async () => {
  try {
    const res = await api.get("/player/getform", { timeout: 10 * 1000 });
    if (res.status === 400 || res.status === 500) {
      throw res.data;
    }
    return res.data.result;
  } catch (error) {
    toast.error("An Error on Fetching Data");
    throw error;
  }
};

export const registerForm = async (data) => {
  try {
    await api.post("player/addform", { ...data });
  } catch (error) {
    toast.error("Unable to upload your form!");
    throw error;
  }
};

export const getFormByID = async (id) => {
  try {
    const res = await api.get(`/player/getformbyid/${id}`, {timeout: 10 * 1000})
    if (res.status === 400 || res.status === 500) {
      throw res.data;
    }
    return res.data.result[0];
  } catch (error) {
    toast.error("Unable to get Form!")
    throw error
  }
}

export const deleteRegister = async(id)=>{
  try {
    const res = await api.delete(`/player/deleteform/${id}`, {timeout: 10 * 1000})
    if (res.status === 400 || res.status === 500) {
      throw res.data
    }
  } catch (error) {
    toast.error("Unable to delete form!")
  }
}

export const addWhitelist = async (data) => {
  try {
    const res = await api.post(`/player/addwhitelist`, {...data})
    if (res.status === 201){
      toast.success("Successfully add whitelist")
    }
  } catch (error) {
    toast.error("Unable to add whitelist!")
  }
}

