import React from "react";
import "./Thankyou.css";

const Thankyou = () => {
  return (
    <div className="flexCenter thankyouwrapper">
      <div className="flexColCenter thankyouContainer">
        <h1>Thank you!!!</h1>
        <div className="flexColCenter thankyouDetail">
          <span>You registered successfully.</span>
          <span>Your Registration is under proceess.</span>
        </div>
        <h2>Join us to receive lastest update from our server!!!</h2>
        <div className="flexCenter thankyouMedia">
          <a href="https://discord.gg/CCQnJxHU8t" target="_blank">
            <img
              src="../assets/icons/discord.png"
              alt="discordIcon"
              width={35}
            />
          </a>
          <a href="https://www.facebook.com/noreacityroleplay" target="_blank">
            <img
              src="../assets/icons/facebook.png"
              alt="facebookIcon"
              width={35}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Thankyou;
