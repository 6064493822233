import React, { useState } from "react";
import "./RegisterDetail.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import {
  addWhitelist,
  api,
  deleteRegister,
  getFormByID,
} from "../../utils/api";
import Error from "../../components/Error/Error.tsx";
import Loading from "../../components/Loading/Loading.tsx";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store.ts";

const RegisterDetail = () => {
  const [t, i18n] = useTranslation("global");
  const { pathname } = useLocation();
  const id = pathname.split("/").slice(-1)[0];
  const navigate = useNavigate();
  const {user} = useSelector((state: RootState)=>(state.auth))
  // const { mutate:approvalMutate } = useMutation({

  //   mutationFn: () => addWhitelist({ ...state }),
  //   onError: ({ response }) =>
  //     toast.error(response.data.message, { position: "bottom-right" }),
  //   onSettled: () => {
  //     toast.success("Register Successfully!");
  //   },
  // });

  // const {mutate:declineMutate} = useMutation({
  //   mutationFn: () => deleteRegister(id),
  //   onError: ({response}) => toast.error(response.data.message, {position: "bottom-right"}),
  //   onSettled: ()=>{
  //     toast.success("Delete Successfully!")
  //   }
  // })

  const { data, isLoading, isError } = useQuery(["getFormById", id], () =>
    getFormByID(id)
  );

  if (isError) {
    return <Error />;
  }

  if (isLoading) {
    return <Loading />;
  }

  const handleApproval = () => {
    var playername = data.name1 + " / " + data.name2;
    api
      .post(`/player/addwhitelist`, {
        license: data.license,
        name: playername,
        addby: user,
      })
      .then((res) => {
        if (res.status == 201) {
          toast.success("Player Whitelisted!");
          api
            .post(`/player/updateformstatus/${id}`, { timeout: 10 * 1000 })
            .then((res) => {
              if (res.status === 201) {
                toast.success("Form Updated!!!");
                navigate(`../`);
              }
            })
            .catch((err) => {
              toast.error("Error Update Form!!!");
            });
        }
      })
      .catch((err) => {
        toast.error("Failed to add player whitelist!");
      });
  };

  const handleDelete = () => {
    api
      .delete(`/player/deleteform/${id}`, { timeout: 10 * 1000 })
      .then((res) => {
        if (res.status === 201) {
          toast.error("Register Deleted!!!");
          navigate(`../`);
        }
      })
      .catch((err) => {
        toast.error("Error Delete Register!");
      });
  };

  // const updateState =()=>{

  //   var playername = data.name1 + " / " + data.name2
  //   setState(() => {
  //     return {
  //       license: data.license,
  //       name: playername,
  //       addby: "Website",
  //     };
  //   });
  // }

  return (
    <div className="flexCenter registerDetailWrapper">
      <div className="paddings flexCenter registerDetailContainer">
        <div className="flexStart registerDetailTitle">
          <h1>Register Answer</h1>
        </div>
        <div className="flexColCenter nameContianer">
          <div className="flexColStart nameElements">
            <span>1. First Character Name</span>
            <span>Answer: {data.name1}</span>
          </div>
          <div className="flexColStart nameElements">
            <span>2. Second Character Name</span>
            <span>Answer: {data.name2}</span>
          </div>
          <div className="flexColStart nameElements">
            <span>3. License</span>
            <span>Answer: {data.license}</span>
          </div>
          <div className="flexColStart nameElements">
            <span>4. IP Address</span>
            <span>Answer: {data.ipaddress}</span>
          </div>
          <div className="flexColStart nameElements">
            <span>5. {t("form.q1")}</span>
            <span>Answer: {data.q1}</span>
          </div>
          <div className="flexColStart nameElements">
            <span>6. {t("form.q2")}</span>
            <span>Answer: {data.q2}</span>
          </div>
          <div className="flexColStart nameElements">
            <span>7. {t("form.q3")}</span>
            <span>Answer: {data.q3}</span>
          </div>
          <div className="flexColStart nameElements">
            <span>8. {t("form.q4")}</span>
            <span>Answer: {data.q4}</span>
          </div>
          <div className="flexColStart nameElements">
            <span>9. {t("form.q5")}</span>
            <span>Answer: {data.q5}</span>
          </div>
        </div>
        <div className="flexCenter registerDetialButtonContianer">
          <button
            className="registerButtonDecline"
            onClick={() => handleDelete()}
          >
            Decline
          </button>
          <button
            className="registerButtonApprove"
            onClick={() => handleApproval()}
          >
            Approve
          </button>
        </div>
      </div>
    </div>
  );
};

export default RegisterDetail;
