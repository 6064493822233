import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  user: null,
  token: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(state, action) {
      localStorage.clear()
      state.user = action.payload.users
      state.token = action.payload.token
  },
    logout(state) {
      state.user = null;
      state.token = null;
      localStorage.clear();
    },
  }, 
})

export const {login, logout} = authSlice.actions

export default authSlice.reducer