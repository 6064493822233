import React, { useEffect, useState } from "react";
import "./RegisterList.css";
import DataTable from "react-data-table-component";
import {useNavigate} from "react-router-dom"
import { api } from "../../utils/api";
import { toast } from "react-toastify";

const RegisterList = () => {
  const navigate = useNavigate()
  const [register, setRegister] = useState([])

  const handleCheck = (e: string) => {
    navigate(`../admin/${e}`)
  };

  useEffect(()=>{
    api
      .get("/player/getform")
      .then((res) => {
        const result = res.data.result.filter(register => register.status === 0)
        setRegister(result);
      })
      .catch((err) => {
        toast.error("No Data Found!");
        console.error("Error Fetching Data:", err);
      });
  },[register])


  // const handleDelete = (e) => {
  //   console.log(data)
  //   api
  //   .delete(`/player/deleteform/${data.id}`, {timeout: 10 * 1000})
  //   .then((res)=>{
  //     if(res.status === 201) {
  //       toast.error("Register Deleted!!!")
  //       navigate(`../`)
  //     }
  //   })
  //   .catch((err)=>{
  //     toast.error("Error Delete Register!")
  //   })
  // };

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      width: "10rem",
    },
    {
      name: "Name 1",
      selector: (row) => row.name1,
      width: "20rem",
    },
    {
      name: "Name 2",
      selector: (row) => row.name2,
      width: "20rem",
    },
    {
      name: "License",
      selector: (row) => row.license,
      width: "20rem",
    },
    {
      name: "IP Address",
      selector: (row) => row.ipaddress,
      width: "15rem",
    },
    {
      name: "Action",
      width: "15rem",
      cell: (row) => (
        <div className="flexCenter action-cell">
          <button
            onClick={() => handleCheck(row.id)}
            className="check-button"
            id={row.id}
          >
            Check
          </button>
          {/* <button
            onClick={() => handleDelete(row.id)}
            className="delete-button"
            id={row.id}
          >
            Delete
          </button> */}
        </div>
      ),
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
      },
    },
    rows: {
      style: {
        cursor: "pointer",
      },
    },
  };

  return (
    <div className="flexCenter registerListWrapper">
      <div className="flexCenter registerListContainer">
        <DataTable
          columns={columns}
          data={register}
          customStyles={customStyles}
          pagination
        />
      </div>
    </div>
  );
};

export default RegisterList;
