import React, { useEffect, useState } from "react";
import "./AdminDashboard.css";
import useForm from "../../hooks/useForm.tsx";
import Loading from "../../components/Loading/Loading.tsx";
import Error from "../../components/Error/Error.tsx";
import RegisterList from "../../components/RegisterList/RegisterList.tsx";
import { FaPeopleGroup } from "react-icons/fa6";
import { HiDocumentText } from "react-icons/hi2";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store.ts";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";

const AdminDashboard = () => {
  const [state, setState] = useState("0");
  const { data, isLoading, isError } = useForm();
  const { token } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate()

  useEffect(()=>{
    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decodedToken.exp! < currentTime) {
        localStorage.clear();
        navigate("/signin");
      } else {
        return;
      }
    } else {
      navigate("/signin");
    }
  }, [token])

  if (isError) {
    return <Error />;
  }

  if (isLoading) {
    return <Loading />;
  }

  const handleChangeState = (e: string) => {
    setState(e);
  };

  return (
    <div className="paddings flexCenter adminDashboardWrapper">
      <div className="flexColCenter adminDBContainer">
        <div className="flexStart adminNavigate">
          <ul className="flexStart adminNavElements">
            <li className={state === "0" ? "flexCenter activeTab" : "flexCenter"} onClick={() => handleChangeState("0")}>
              <HiDocumentText
                size={20}
                style={state === "0" ? { fill: "#01eab2" } : { fill: "black" }}
              />
              Registration
            </li>
            <li
              className={state === "1" ? "flexCenter activeTab" : "flexCenter"}
              onClick={() => handleChangeState("1")}
            >
              <FaPeopleGroup
                size={20}
                style={state === "1" ? { fill: "#01eab2" } : { fill: "black" }}
              />
              Players
            </li>
          </ul>
        </div>
        <div className="flexCenter adminDBContent">
          <div className={state === "1" ? "displayContent" : "noDisplay"}>
            Player
          </div>
          <div className={state === "0" ? "displayContent" : "noDisplay"}>
            <RegisterList />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
