import React, { ChangeEvent, useState } from "react";
import "./RegisterForm.css";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { api, registerForm } from "../../utils/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const RegisterForm = () => {
  const [t, i18n] = useTranslation("global");
  const [state, setState] = useState([]);
  const navigate = useNavigate()

  const handleState = (e: ChangeEvent<HTMLInputElement>) => {
    setState((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleTextAreaState = (e: ChangeEvent<HTMLTextAreaElement>) =>{
    setState((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  }

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (state) {
      api
      .post("player/addform",state)
      .then((res)=>{
        if(res.status === 201){
          toast.success("Register Successfully!!!")
          navigate(`../thankyou`)
        }
      })
      .catch((err) => {
        toast.error("Register Fail!");
        console.error("Error Fetching Data:", err);
      });
    } else {
      toast.error("Please input data!!");
    }
  };

  return (
    <div className="paddings flexColCenter homeContainer">
      <div className="flexColCenter formWrapper">
        <form
          onSubmit={(e) => {
            handleOnSubmit(e);
          }}
          className="flexColCenter formContainer"
        >
          <div className="flexCenter formTitle">
            <h1>REGISTRATION FORM</h1>
          </div>
          <div className="flexStart formRow">
            <div className="flexStart formLeftCol">
              <label className="formInputTitle" htmlFor="name1">{t("form.name1")}</label>
            </div>
            <div className="flexStart formRightCol">
              <input
                required
                type="text"
                id="name1"
                name="name1"
                onChange={handleState}
              />
            </div>
          </div>
          <div className="flexStart formRow">
            <div className="flexStart formLeftCol">
              <label className="formInputTitle" htmlFor="name2">{t("form.name2")}</label>
            </div>
            <div className="flexStart formRightCol">
              <input
                required
                type="text"
                id="name2"
                name="name2"
                onChange={handleState}
              />
            </div>
          </div>
          <div className="flexStart formRow">
            <div className="flexStart formLeftCol">
              <label className="formInputTitle" htmlFor="license">{t("form.license")}</label>
            </div>
            <div className="flexStart formRightCol">
              <input
                required
                type="text"
                id="license"
                name="license"
                onChange={handleState}
              />
            </div>
          </div>
          <div className="flexStart formRow">
            <div className="flexStart formLeftCol">
              <label className="formInputTitle" htmlFor="ipaddress">{t("form.ip")}</label>
            </div>
            <div className="flexStart formRightCol">
              <input
                required
                type="text"
                id="ipaddress"
                name="ipaddress"
                onChange={handleState}
              />
            </div>
          </div>
          <div className="flexColStart formRow formQesRow">
            <div className="flexStart formQes">
              <label htmlFor="q1">{t("form.q1")}</label>
            </div>
            <div className="flexStart formAnswer">
              <textarea
                required
                rows={6}
                cols={90}
                id="q1"
                name="q1"
                onChange={handleTextAreaState}
              />
            </div>
          </div>
          <div className="flexColStart formRow formQesRow">
            <div className="flexStart formQes">
              <label htmlFor="q2">{t("form.q2")}</label>
            </div>
            <div className="flexStart formAnswer">
              <textarea
                required
                rows={6}
                cols={90}
                id="q2"
                name="q2"
                onChange={handleTextAreaState}
              />
            </div>
          </div>
          <div className="flexColStart formRow formQesRow">
            <div className="flexStart formQes">
              <label htmlFor="q3">{t("form.q3")}</label>
            </div>
            <div className="flexStart formAnswer">
              <textarea
                required
                rows={6}
                cols={90}
                id="q3"
                name="q3"
                onChange={handleTextAreaState}
              />
            </div>
          </div>
          <div className="flexColStart formRow formQesRow">
            <div className="flexStart formQes">
              <label htmlFor="q4">{t("form.q4")}</label>
            </div>
            <div className="flexStart formAnswer">
              <textarea
                required
                rows={6}
                cols={90}
                id="q4"
                name="q4"
                onChange={handleTextAreaState}
              />
            </div>
          </div>
          <div className="flexColStart formRow formQesRow">
            <div className="flexStart formQes">
              <label htmlFor="q5">{t("form.q5")}</label>
            </div>
            <div className="flexStart formAnswer">
              <textarea
                required
                rows={6}
                cols={90}
                id="q5"
                name="q5"
                onChange={handleTextAreaState}
              />
            </div>
          </div>
          <div className="flexCenter formSubmit">
            <button>Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RegisterForm;
