import { QueryClient, QueryClientProvider } from "react-query";
import "./App.css";
import {BrowserRouter, Routes, Route} from "react-router-dom"
import Layout from "./layout/Layout.tsx";
import AdminDashboard from "./pages/Admin/AdminDashboard.tsx";
import RegisterDetail from "./pages/RegisterDetail/RegisterDetail.tsx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Signin from "./pages/Signin/Signin.tsx";
import Thankyou from "./components/Thankyou/Thankyou.tsx";
import RegisterForm from "./pages/RegisterForm/RegisterForm.tsx";
import Announcement from "./pages/Anncounecment/Announcement.tsx";

function App() {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <BrowserRouter>
        <Routes>
          <Route element={<Layout/>}>
            <Route path="/" element={<Announcement/>}></Route>
            <Route path="/register">
              <Route index  element={<RegisterForm/>}/>
            </Route>
            <Route path="/admin" >
              <Route index element={<AdminDashboard/>}/>
              <Route path=":id" element={<RegisterDetail/>}/>
            </Route>           
            <Route path="/thankyou" element={<Thankyou/>}/>
          </Route>
          <Route path="/signin" element={<Signin/>}/>
        </Routes>
        </BrowserRouter>
      <ToastContainer />
      </div>
    </QueryClientProvider>
  );
}

export default App;
