import React from "react";
import './Loading.css'
import { PuffLoader } from "react-spinners";

const Loading = () => {
  return (
    <div className="flexCenter loadingWrapper">
      <PuffLoader height="80" width="80" radius={1} aria-label="puff-loading" />
    </div>
  );
};

export default Loading;
