import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import global_en from "./translation/en/global.json";
import global_kh from "./translation/kh/global.json";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import {store}  from './redux/store.ts'

i18next.init({
  interpolation: { escapeValue: true },
  lng: "en",
  resources: {
    en: {
      global: global_en,
    },
    kh: {
      global: global_kh,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </Provider>
  </React.StrictMode>
);
