import React, { ChangeEvent, useState } from "react";
import "./Signin.css";
import { useDispatch } from "react-redux";
import { api } from "../../utils/api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { login } from "../../redux/authSlice.js"

const Signin = () => {
  const [state, setState] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleState = (e: ChangeEvent<HTMLInputElement>) => {
    setState((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleSignin = (e) => {
    e.preventDefault();
    api
      .post("/user/signin", { ...state })
      .then((res) => {
        if (res.status === 200) {
          dispatch(login(res.data))
          navigate(`/admin`);
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error("Invaild Credential!");
      });
  };

  return (
    <div className="flexCenter signinWrapper">
      <div className="paddings flexColCenter signinContainer">
        <img
          src="../assets/images/logoNoreaCityRP.png"
          alt="logo"
          width={150}
        />
        <div className="signinTitle">
          <h1>Sign in</h1>
        </div>
        <form className="flexColCenter signinForm"  onSubmit={(e) => {
            handleSignin(e);
          }}>
          <div className="signinUsername">
            <input
              onChange={handleState}
              className="inputField"
              name="username"
              type="text"
              placeholder="Username"
              required
            />
          </div>
          <div className="signinPassword">
            <input
              onChange={handleState}
              className="inputField"
              name="password"
              type="password"
              placeholder="Password"
              required
            />
          </div>
          <div className="flexColCenter signinButtons">
            <button>Sign In</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Signin;
